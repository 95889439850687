import React from 'react';
import Hero from './assets/images/mbpOutstanding.png';
import MasteryImage from './assets/images/iPad.png';
import AnalysisImage from './assets/images/mbpSensitivity.png';
import SupportImage from './assets/images/iPad success.png';
import Mobile from './assets/images/newMobile.png';
import { UTIL } from './assets/js/main';
import { Helmet } from 'react-helmet';
import BondBuyerPoster from './assets/images/bond_buyer_poster.jpeg';

class Home extends React.Component {
    videoRef = React.createRef();

    onVideoComplete = () => this.videoRef.current.load();

    componentDidMount() {
        UTIL.loadEvents();
        window.scrollTo(0, 0);
        this.videoRef.current.addEventListener('ended', this.onVideoComplete);
    }

    componentWillUnmount() {
        this.videoRef.current.removeEventListener(
            'ended',
            this.onVideoComplete
        );
    }

    title = 'Adaje - The Muni Platform';
    description =
        'Adaje is the only software platform designed to streamline the entire bond issuance lifecycle: ' +
        'from structuring ideas to marketing to post-issuance management and disclosure. ' +
        'Built for issuers, bankers, advisors, and investors. ' +
        'Adaje eliminates data entry, automates analysis and monitoring, connects market participants, ' +
        'and simplifies access to your data.';
    render() {
        return (
            <body className='home'>
                <Helmet>
                    <title>{this.title}</title>
                    <meta name='description' content={this.description} />
                    <meta property='og:title' content={this.title} />
                    <meta
                        property='og:description'
                        content={this.description}
                    />
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta
                        name='twitter:description'
                        content={this.description}
                    />
                    <meta name='twitter:title' content={this.title} />
                </Helmet>
                <a
                    href='#skipnavigation'
                    className='skip-navigation'
                    id='backtotop'>
                    Skip Navigation
                </a>
                <div className='site-wrapper'>
                    {this.props.mainNav}
                    {this.props.mobileNav}
                    <main className='global-main' id='skipnavigation'>
                        <section className='home-hero hero-animation'>
                            <div className='screen-wrapper'>
                                <img className='screen' src={Hero} />
                            </div>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='content-styled'>
                                            <h6>
                                                Debt Modeling, Marketing, &
                                                Management
                                            </h6>
                                            <h1 className='super'>
                                                The Muni Bond Platform
                                            </h1>
                                            <p>{this.description}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='row numbers'>
                                    <div className='col-md-4'>
                                        <p className='h1 number'>2</p>
                                        <p className='label'>
                                            <strong>seconds</strong> to model an
                                            issue from an official statement
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p className='h1 number'>1,000+</p>
                                        <p className='label'>
                                            <strong>refundings</strong>{' '}
                                            evaluated in under a minute
                                        </p>
                                    </div>
                                    <div className='col-md-4'>
                                        <p className='h1 number'>{'<30'}</p>
                                        <p className='label'>
                                            <strong>seconds</strong> to
                                            calculate and summarize over 25
                                            financing scenarios
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='split-column bg-light-blue'>
                            <div className='container'>
                                <div className='row start-md p-0'>
                                    <div className='col-md-5'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h2>
                                                    Featured in The Bond
                                                    Buyer&apos;s Advances In
                                                    Tech
                                                </h2>
                                                <p>
                                                    Watch our presentation from
                                                    the{' '}
                                                    <a href='https://www.bondbuyer.com/video/software-platform-to-streamline-the-entire-bond-issuance-lifecycle-adaje'>
                                                        2023 Advances In Tech
                                                        Demo Day
                                                    </a>{' '}
                                                    to see some of our groundbreaking features in action!
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-7'>
                                        <video
                                            ref={this.videoRef}
                                            width='100%'
                                            controls
                                            poster={BondBuyerPoster}
                                            src='https://21944595.fs1.hubspotusercontent-na1.net/hubfs/21944595/Bond%20Buyer%20Advances%20In%20Tech%20Demo.mp4'
                                        />
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='split-column bg-blue screen-mastery'>
                            <div className='screen-wrapper'>
                                <img className='screen' src={MasteryImage} />
                            </div>
                            <div className='container'>
                                <div className='row end-md'>
                                    <div className='col-md-5'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h2>
                                                    Single, Unified Platform
                                                </h2>
                                                <p>
                                                    Achieve full control of your
                                                    debt functions in one place
                                                    place - replacing
                                                    yesterday’s piecemeal
                                                    solutions. Gain a bird’s eye
                                                    view of your organization’s
                                                    or client’s debt. Identify
                                                    actionable opportunities.
                                                    Automate upkeep and
                                                    monitoring. Never miss a
                                                    refunding opportunity.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='split-column bg-light-blue screen-analysis'>
                            <div className='screen-wrapper'>
                                <img className='screen' src={AnalysisImage} />
                            </div>
                            <div className='container'>
                                <div className='row start-md'>
                                    <div className='col-md-5'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h2>
                                                    Complex Analysis, Automated
                                                </h2>
                                                <p>
                                                    Build out intricate models
                                                    rapidly and accurately.
                                                    Quickly analyze, optimize,
                                                    and share debt and swap
                                                    structures. Automate
                                                    analytical permutations.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='split-column bg-orange screen-support'>
                            <div className='screen-wrapper'>
                                <img className='screen' src={SupportImage} />
                            </div>
                            <div className='container'>
                                <div className='row start-md'>
                                    <div className='col-md-5'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h2>
                                                    Customer Success, Elevated
                                                </h2>
                                                <p>
                                                    Rely on premium in-app
                                                    support and learning
                                                    resources. Comprehensive
                                                    courses with quizes, tracked
                                                    progress, and certifications
                                                    ensure employees learn key
                                                    concepts and workflows.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='split-column bg-light-blue screen-mobile'>
                            <div className='screen-wrapper'>
                                <img className='screen' src={Mobile} />
                            </div>
                            <div className='container'>
                                <div className='row start-md'>
                                    <div className='col-md-5'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h2>Work as One</h2>
                                                <p>
                                                    Seamlessly share and
                                                    collaborate across
                                                    departments and the finance
                                                    working group. Control
                                                    access with limited or
                                                    view-only permissions.
                                                    Simultaneous access to
                                                    models and documents in one
                                                    place.
                                                </p>
                                            </div>
                                            <a
                                                href='https://help.adajeinc.com/meetings/dan-silva/personalized-adaje-demo'
                                                className='landing-btn'>
                                                Schedule a Demo
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
                    {this.props.footer}
                </div>
            </body>
        );
    }
}

export default Home;
