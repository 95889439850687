const setHost = () => {
    //local
    return window.location.hostname === 'localhost'
        ? 'http://localhost:3001/api'
        : //dev-cluster
        window.location.hostname === 'home.adaje.net'
        ? 'https://api.adaje.net'
        : //prod-cluster
          'https://api.adajeinc.com';
};

const setLoginRedirectUrl = () => {
    //local
    return window.location.hostname === 'localhost'
        ? 'http://localhost:3000'
        : //dev-cluster
        window.location.hostname === 'home.adaje.net'
        ? 'https://app.adaje.net'
        : //prod-cluster
          'https://app.adajeinc.com';
};

export const constants = {
    host: setHost(),
    loginRedirectUrl: setLoginRedirectUrl(),
    urlPrefix: '/v006',
    tokenRefreshTimeout: 1000 * 60 * 30, //Thirty minutes, token expires in 1hr
    logoutDueToInactiveTimeout: 1000 * 60 * 60 * 24 //A day
};
