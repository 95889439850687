import $ from 'jquery';

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Base = {
    // All pages
    common: {
        init: function() {
            /////
            // JavaScript to be fired on all pages
            /////

            // Animations
            setTimeout(function() {
                $('.hero-animation').addClass('visible');
            }, 100);
            function revealImages() {
                $('img, .headshot').each(function() {
                    if ($(this).offset().top < $(window).scrollTop() + $(window).height() - 200) {
                        $(this).addClass('visible');
                    }
                });
            }
            revealImages();
            $(window).scroll(function() {
                revealImages();
            });

            // Anchor
            $('a').on('click', function(e) {
                var $href = $(this).attr('href');
                if ($href.match('^#') && !$(this).hasClass('accordion-tab')) {
                    e.preventDefault();
                    $('html, body').stop().animate({
                        scrollTop: $($href).offset().top
                    });
                }
            });

            // Video popups
            $('a').on('click', function(e) {
                var $target = $(this).attr('href');
                if ($target.toLowerCase().indexOf('vimeo') >= 0) {
                    e.preventDefault();
                    var $n = $target.lastIndexOf('/');
                    var $vid = $target.substring($n + 1);
                    $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
                    $('.video-popup-wrapper').addClass('active');
                    $('body, html').addClass('body-noscroll');
                }
                if ($target.toLowerCase().indexOf('youtu') >= 0) {
                    e.preventDefault();
                    var $y = $target.lastIndexOf('=');
                    var $yid = $target.substring($y + 1);
                    $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
                    $('.video-popup-wrapper').addClass('active');
                    $('body, html').addClass('body-noscroll');
                }
            });
            $('.close-popup').on('click', function() {
                $('.video-popup-wrapper').removeClass('active');
                $('.popup-content').html('');
                $('body, html').removeClass('body-noscroll');
            });

            // Mobile Nav
            $('#mobile-nav-toggle').on('click', function() {
                var $navheight = $('#mobile-navigation').height() + 170;
                $('body, html').toggleClass('nav-locked');
                $('#mobile-navigation-wrapper').toggleClass('active');
                $(this).toggleClass('active');
            });

            // Resize for Match-Height and Slick-Slider glitch fix
            $(window).bind('load', function() {
                $(window).trigger('resize');
                setTimeout(function() { $(window).trigger('resize'); }, 500);
            });
        }
    },
    // Home
    home: {
        init: function() {
            // JavaScript to be fired on the home page
            console.log('home');
        }
    },
    // Company
    company: {
        init: function() {
            // JavaScript to be fired on the home page
            console.log('company');
        }
    },
    // Borrowers
    borrowers: {
        init: function() {
            // JavaScript to be fired on the home page
            console.log('borrowers');
        }
    },
    // Advisors
    advisors: {
        init: function() {
            // JavaScript to be fired on the home page
            console.log('advisors');
        }
    },
    // Privacy
    privacy: {
        init: function() {
            // JavaScript to be fired on the home page
            console.log('privacy');
        }
    },
    // Demo
    demo: {
        init: function() {
            // JavaScript to be fired on the home page
            console.log('demo');
        }
    }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
    fire: function(func, funcname, args) {
        var namespace = Base;
        funcname = (funcname === undefined) ? 'init' : funcname;
        if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
            namespace[func][funcname](args);
        }
    },
    loadEvents: function() {
        UTIL.fire('common');

        $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
            UTIL.fire(classnm);
        });
    }
};

export { UTIL };