import React from 'react';
import { UTIL } from './assets/js/main';
import DanHeadshot from './assets/images/headshot_dan.jpg';
import JeffHeadshot from './assets/images/headshot_jeff.jpg';
import { Helmet } from 'react-helmet';

export default class Company extends React.Component {
    componentDidMount() {
        UTIL.loadEvents();
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <body className='company'>
                <Helmet>
                    <title>Adaje – Our Company</title>
                    <meta
                        name='description'
                        content='Adaje is the best debt management and modeling platform for Public Finance. Solutions exist for advisors, bankers, and borrowers. Request a demo.'
                    />
                    <meta property='og:title' content='Adaje – Our Company' />
                    <meta
                        property='og:description'
                        content='Adaje is the best debt management and modeling platform for Public Finance. Solutions exist for advisors, bankers, and borrowers. Request a demo.'
                    />
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta
                        name='twitter:description'
                        content='Adaje is the best debt management and modeling platform for Public Finance. Solutions exist for advisors, bankers, and borrowers. Request a demo.'
                    />
                    <meta name='twitter:title' content='Adaje – Our Company' />
                </Helmet>
                <a
                    href='#skipnavigation'
                    className='skip-navigation'
                    id='backtotop'>
                    Skip Navigation
                </a>
                <div className='site-wrapper'>
                    {this.props.mainNav}
                    {this.props.mobileNav}
                    <main className='global-main' id='skipnavigation'>
                        <section className='company-hero hero-animation'>
                            <div className='container'>
                                <div className='row center-md'>
                                    <div className='col-lg-8 col-md-10'>
                                        <h6>
                                            Revolutionize. Improve Efficiency.
                                            Reduce Costs.
                                        </h6>
                                        <h1>Our Mission</h1>
                                        <h3>
                                            Empower the public finance industry
                                            with technology to fundamentally
                                            transform the financing of the
                                            nation’s hospitals, infrastructure,
                                            schools, and projects critical to
                                            the public good.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='about'>
                            <div className='container'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h4>The Name</h4>
                                                <p>
                                                    Our name, Adaje, is a
                                                    partial anagram of our
                                                    founders’ names and a
                                                    homophone of the word
                                                    “adage”.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h4>What it Means</h4>
                                                <p>
                                                    An “adage” is a concise,
                                                    memorable, and usually
                                                    philosophical aphorism that
                                                    communicates an important
                                                    truth derived from
                                                    experience, custom, or both,
                                                    and that many people
                                                    consider true and credible
                                                    because of its longeval
                                                    tradition, i.e. being handed
                                                    down generation to
                                                    generation, or memetic
                                                    replication.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className='content-wrapper'>
                                            <div className='content-styled'>
                                                <h4>And Why</h4>
                                                <p>
                                                    We like the association
                                                    since our platform is
                                                    helping derive important
                                                    “truths” of the public
                                                    finance industry through
                                                    data.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className='founders'>
                            <div className='container'>
                                <h2 className='section-title'>
                                    Meet Our Founders
                                </h2>
                            </div>
                            <div className='founder'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div
                                                className='headshot'
                                                style={{
                                                    backgroundImage:
                                                        'url(' +
                                                        DanHeadshot +
                                                        ')'
                                                }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='meta'>
                                                <h4 className='name'>
                                                    Dan Silva
                                                </h4>
                                                <p className='title small'>
                                                    Chief Executive Officer
                                                </p>
                                            </div>
                                            <div className='content-styled'>
                                                <p>
                                                    Mr. Silva has over fifteen
                                                    years of experience in the
                                                    public finance industry and
                                                    ten years developing
                                                    software. He has advised
                                                    borrowers across the rating
                                                    spectrum on virtually every
                                                    type of financing. Having
                                                    experienced and been
                                                    disappointed with the
                                                    technological offerings in
                                                    public finance, he
                                                    co-founded Adaje Inc. to
                                                    develop a modern, integrated
                                                    debt modeling and management
                                                    platform.
                                                </p>
                                                <p>
                                                    Mr. Silva is also a
                                                    co-founder of Callowhill
                                                    Capital Advisors, a
                                                    financial advisory firm
                                                    known for their analytical
                                                    expertise and exceptional
                                                    service. Mr. Silva graduated
                                                    magna cum laude with his
                                                    Bachelor of Science in
                                                    Business Administration with
                                                    concentrations in Finance
                                                    and Economics from Drexel
                                                    University. Mr. Silva is a
                                                    CFA charterholder and Series
                                                    50 and Series 54 licensee.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='founder'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div
                                                className='headshot'
                                                style={{
                                                    backgroundImage:
                                                        'url(' +
                                                        JeffHeadshot +
                                                        ')'
                                                }}>
                                                &nbsp;
                                            </div>
                                        </div>
                                        <div className='col-md-8'>
                                            <div className='meta'>
                                                <h4 className='name'>
                                                    Jeffrey Sherman
                                                </h4>
                                                <p className='title small'>
                                                    Chief Technology Officer
                                                </p>
                                            </div>
                                            <div className='content-styled'>
                                                <p>
                                                    Mr. Sherman has extensive
                                                    experience in software
                                                    development and in the
                                                    application of machine
                                                    learning across a broad
                                                    range of industries and
                                                    problem settings. Before
                                                    co-founding Adaje, he worked
                                                    as a Scientist where he
                                                    performed on DARPA and IARPA
                                                    sponsored research and
                                                    development projects
                                                    involving Natural Language
                                                    Processing, Computer Vision,
                                                    and Graph Mining. In 2012,
                                                    he joined a small startup,
                                                    eventually acquired by
                                                    United Healthcare, where he
                                                    oversaw the development of a
                                                    state-of-the-art
                                                    recommendation engine
                                                    leveraging health and
                                                    behavioral data at scale.
                                                </p>
                                                <p>
                                                    Recently, he has been
                                                    principally involved in the
                                                    incubation of a cancer
                                                    therapeutics company
                                                    applying advanced machine
                                                    learning to novel biomarker
                                                    discovery and drug
                                                    repositioning. Mr. Sherman
                                                    holds a master's degree from
                                                    the University of
                                                    Pennsylvania in Computer
                                                    Science and a B.S. degree
                                                    from George Mason
                                                    University, also in Computer
                                                    Science.
                                                </p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/*<section className="client">*/}
                        {/*<div className="container">*/}
                        {/*<div className="row between-md">*/}
                        {/*<div className="col-md-6">*/}
                        {/*<div className="content-wrapper">*/}
                        {/*<div className="content-styled">*/}
                        {/*<h6>Client Success</h6>*/}
                        {/*<h2>Titles for A Success Story with a Specific Client That Would Look Like This</h2>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*<img className="logo-image" src={CallowhillLogo}/>*/}
                        {/*</div>*/}
                        {/*<div className="col-md-6 col-lg-5">*/}
                        {/*<div className="content-styled">*/}
                        {/*<h5>Challenge</h5>*/}
                        {/*<ul>*/}
                        {/*<li>Easily share and collaborate in Adaje's multi-user environment. Changes made by any user are*/}
                        {/*automatically viewable by other permitted users. Share custom limited view-only access across*/}
                        {/*departments and with external consultants, bankers and auditors.*/}
                        {/*</li>*/}
                        {/*<li>Easily share and collaborate in Adaje's multi-user environment. Changes made by any user are*/}
                        {/*automatically viewable by other permitted users. Share custom limited view-only access across*/}
                        {/*departments and with external consultants, bankers and auditors.*/}
                        {/*</li>*/}
                        {/*</ul>*/}
                        {/*<h5>Results</h5>*/}
                        {/*<ul>*/}
                        {/*<li>Easily share and collaborate in Adaje's multi-user environment. Changes made by any user are*/}
                        {/*automatically viewable by other permitted users. Share custom limited view-only access across*/}
                        {/*departments and with external consultants, bankers and auditors.*/}
                        {/*</li>*/}
                        {/*</ul>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        {/*</section>*/}
                    </main>
                    {this.props.footer}
                </div>
            </body>
        );
    }
}
