import React from 'react';
import { UTIL } from './assets/js/main';
import AdvisorsScreen from './assets/images/mbpAdvisorBanker.png';
import { Helmet } from 'react-helmet';

export default class AdvisorBankerPage extends React.Component {
    componentDidMount() {
        UTIL.loadEvents();
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <body className='advisors'>
                <Helmet>
                    <title>Adaje – For Advisors and Bankers</title>
                    <meta
                        name='description'
                        content='Adaje for Advisors and Bankers is a modern, powerful debt management and modeling platform offering revolutionary time saving features. Request a demo.'
                    />
                    <meta
                        property='og:title'
                        content='Adaje – For Advisors and Bankers'
                    />
                    <meta
                        property='og:description'
                        content='Adaje for Advisors and Bankers is a modern, powerful debt management and modeling platform offering revolutionary time saving features. Request a demo.'
                    />
                    <meta name='twitter:card' content='summary_large_image' />
                    <meta
                        name='twitter:description'
                        content='Adaje for Advisors and Bankers is a modern, powerful debt management and modeling platform offering revolutionary time saving features. Request a demo.'
                    />
                    <meta
                        name='twitter:title'
                        content='Adaje – For Advisors and Bankers'
                    />
                </Helmet>
                <a
                    href='#skipnavigation'
                    className='skip-navigation'
                    id='backtotop'>
                    Skip Navigation
                </a>
                <div className='site-wrapper'>
                    {this.props.mainNav}
                    {this.props.mobileNav}
                    <main className='global-main' id='skipnavigation'>
                        <section className='page-hero hero-animation'>
                            <div className='container'>
                                <h6>Identify. Analyze. Capitalize.</h6>
                                <h1>Adaje for Advisors & Bankers</h1>
                                <h3>
                                    The best way to model and share debt and
                                    swap structures and stay on top of refunding
                                    opportunities.
                                </h3>
                            </div>
                        </section>
                        <section className='page-content'>
                            <div className='container'>
                                <div className='row center-md'>
                                    <div className='col-lg-8 col-md-10'>
                                        <div className='intro-content'>
                                            <div className='content-styled'>
                                                {/*<h2> Minimize data entry and realize revolutionary time savings while performing high value-added*/}
                                                {/*analyses and more quickly identifying client opportunities.</h2>*/}
                                                <h3>
                                                    Adaje empowers users across
                                                    the organization: from{' '}
                                                    <strong>Analysts</strong>{' '}
                                                    modeling complex
                                                    transactions to{' '}
                                                    <strong>
                                                        Managing Directors
                                                    </strong>{' '}
                                                    evaluating structure
                                                    alternatives or assessing a
                                                    prospect's debt portfolio.
                                                </h3>
                                            </div>
                                        </div>
                                        <div className='icons-wrapper'>
                                            <ul className='icon-list'>
                                                <li className='list-item'>
                                                    <div className='icon-wrapper'>
                                                        <svg
                                                            className='icon'
                                                            width='40'
                                                            height='37'
                                                            viewBox='0 0 40 37'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                                d='M0 30.1667H5L8.33333 26H11.6667L15 29.3333H20L26.6667 22.6667H32.5L35 18.5H40'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M40 35.1667H0'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M23.3327 22.6667V10.1667H16.666V17.6667'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M23.334 35.1666V29.3333'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M30 25.1667V35.1667'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M23.334 13.5H30.0007V20.1667'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M16.666 31.8333V35.1666'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M10 17.6667H16.6667V26.8334'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M3.33398 35.1667V32.6667'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M10 28.5V35.1667'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M3.33398 27.6666V14.3333H10.0007V23.4999'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M36.666 21V35.1667'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M36.6667 16V1H30V13.5'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h5>
                                                            Powerful Analytics
                                                        </h5>
                                                        <p className='small'>
                                                            Perform refunding
                                                            analyses across your
                                                            organization’s
                                                            entire database of
                                                            transactions for all
                                                            clients at once.
                                                            Instantly view and
                                                            export
                                                            professionally-formatted
                                                            reports and charts
                                                            across a borrower’s
                                                            entire debt profile.
                                                            Efficiently and
                                                            intelligently
                                                            identify
                                                            opportunities.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className='list-item'>
                                                    <div className='icon-wrapper'>
                                                        <svg
                                                            className='icon'
                                                            width='42'
                                                            height='40'
                                                            viewBox='0 0 42 40'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                                d='M8.49999 27.6666C4.35 27.6666 1 24.3166 1 20.1666C1 17.7833 2.11667 15.65 3.85 14.2833C4.9 13.45 6.16666 12.9 7.54999 12.7333C7.84999 12.7 8.18333 12.6666 8.49999 12.6666C9.58333 12.6666 10.6 12.9 11.5333 13.3'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M30.2002 15.7667C31.1335 15.0334 32.2502 14.55 33.4835 14.3834C33.7669 14.35 34.0502 14.3334 34.3335 14.3334C35.5335 14.3334 36.6669 14.65 37.6335 15.2C39.6502 16.35 41.0002 18.5 41.0002 21C41.0002 24.6834 38.0169 27.6667 34.3335 27.6667'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M11.8333 27.6666H8.5'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M34.3332 27.6666H30.1665'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M23.2668 11.3333C23.4168 10.7 23.5001 10.0333 23.5001 9.33333C23.5001 8.71666 23.4335 8.13333 23.3168 7.54999C23.0001 6.13333 22.3335 4.85 21.4168 3.8C19.8835 2.08333 17.6502 1 15.1668 1C10.7502 1 7.1335 4.43333 6.85016 8.76666C6.8335 8.94999 6.8335 9.14999 6.8335 9.33333C6.8335 10.5333 7.0835 11.7 7.55016 12.7333'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M33.484 14.3834C33.5007 14.2334 33.5007 14.0667 33.5007 13.9167C33.5007 12.55 33.1174 11.2834 32.434 10.2C31.3507 8.45004 29.5174 7.20004 27.384 6.90004C27.0674 6.85004 26.7507 6.83337 26.4174 6.83337C25.3007 6.83337 24.2507 7.08337 23.3174 7.55004'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M18.4831 20.0333V30.85H14.2998L20.9998 38.3666L27.6998 30.85H23.5165V20.0333H18.4831Z'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h5>
                                                            Documents In,
                                                            Reports Out
                                                        </h5>
                                                        <p className='small'>
                                                            Ingest official
                                                            statements into
                                                            structured models
                                                            with our proprietary
                                                            machine learning and
                                                            natural language
                                                            processing engine,
                                                            enabling quicker
                                                            access to performing
                                                            higher level
                                                            analysis and
                                                            exporting reports.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className='list-item'>
                                                    <div className='icon-wrapper'>
                                                        <svg
                                                            className='icon'
                                                            width='43'
                                                            height='37'
                                                            viewBox='0 0 43 37'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                                d='M33.7004 13.5556L37.2559 10L39.9226 12.6667L41.7004 2L31.0337 3.77778L33.7004 6.44444L31.0337 9.11111'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M21.2558 30.4445C27.6377 30.4445 32.8113 25.2709 32.8113 18.8889C32.8113 12.507 27.6377 7.33337 21.2558 7.33337C14.8738 7.33337 9.7002 12.507 9.7002 18.8889C9.7002 25.2709 14.8738 30.4445 21.2558 30.4445Z'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M29.2559 26.8889L37.7003 35.3334'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M27.4779 12.6666L20.989 19.1555C20.6334 19.5111 20.1001 19.5111 19.7445 19.1555L14.1445 13.5555'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M30.1445 17.1111L22.2334 25.0222C21.1667 26.0889 19.4778 26.0889 18.5 25.0222L12.3667 18.8889'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M10.5891 9.99997L8.01131 7.42219C6.94464 6.35553 5.25575 6.35553 4.27797 7.42219L1.7002 9.99997'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M7.92242 14.4444L6.76686 13.2889C6.41131 12.9333 5.87797 12.9333 5.52242 13.2889L1.7002 17.1111'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h5>
                                                            Rich Feature Set
                                                        </h5>
                                                        <p className='small'>
                                                            Model virtually any
                                                            type of debt and
                                                            swaps and compare
                                                            across competing
                                                            structures. Adaje
                                                            automatically
                                                            imports data feeds
                                                            for SLGS, SIFMA and
                                                            other data sets
                                                            without requiring
                                                            the user to import a
                                                            file. Adaje also
                                                            comes packed with a
                                                            document depot, PV
                                                            calculator, and much
                                                            more.
                                                        </p>
                                                    </div>
                                                </li>
                                                <li className='list-item'>
                                                    <div className='icon-wrapper'>
                                                        <svg
                                                            className='icon'
                                                            width='42'
                                                            height='45'
                                                            viewBox='0 0 42 45'
                                                            fill='none'
                                                            xmlns='http://www.w3.org/2000/svg'>
                                                            <path
                                                                d='M41.6187 21.6991H35.0094C34.0753 21.6991 33.1649 21.4286 32.3846 20.9111L31.9353 20.6054C31.1549 20.0879 30.2445 19.8174 29.3104 19.8174H22.1454C21.4714 19.8174 20.8093 20.0644 20.2891 20.4995L17.5815 23.228C17.5815 23.228 16.1863 24.6393 14.7793 26.1094C16.2099 27.5325 17.7588 27.6266 19.0949 27.1679C20.431 26.7092 22.1927 25.4626 22.1927 25.4626H24.5928L28.1281 28.7321'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M34.4424 35.0712C35.3173 34.2009 36.4051 33.9304 37.8357 33.9304'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M28.1281 28.7321C28.1281 28.7321 33.0349 33.8598 34.4537 35.0712C35.8725 36.2825 33.9216 39.5168 31.6397 37.7409C30.28 36.5765 25.9053 32.4132 25.9053 32.4132'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M32.2783 38.1173C31.8645 39.1052 30.1737 41.4103 28.0337 39.7991C26.674 38.6465 23.8481 35.9768 23.8481 35.9768'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M28.8493 40.2577C27.8798 42.21 26.1654 42.998 24.6165 41.7866C23.5287 40.9399 21.8853 39.2581 21.8853 39.2581'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M25.1249 42.1041C24.5337 43.5507 22.8548 44.4916 21.4951 43.6448C20.7147 43.1626 19.2959 42.1276 19.2959 42.1276'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M0 21.6992H8.31195C9.05684 21.6992 9.7899 21.4757 10.4165 21.0641L11.3506 20.4408C11.9773 20.0291 12.6985 19.8057 13.4552 19.8057H18.0309C18.4447 19.8057 18.8586 19.9468 19.1896 20.1938L20.0054 20.7465'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M10.6886 36.3884C11.3626 35.7416 11.2443 34.8007 10.5941 34.1304L10.5822 34.1186C9.92011 33.4365 8.84417 33.4247 8.15841 34.0833L7.18887 35.0007C6.50311 35.6593 6.49129 36.7413 7.1534 37.4116C7.81552 38.0938 8.90329 38.1173 9.58905 37.4469'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M14.6143 34.9889C13.9522 34.3068 12.8644 34.2833 12.1787 34.9419L9.36466 37.6704C8.67889 38.329 8.66707 39.411 9.32919 40.0814C9.9913 40.7635 11.0791 40.787 11.7648 40.1167L14.567 37.3999C15.2528 36.7531 15.2646 35.6593 14.6143 34.9889Z'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M12.2376 39.6579C11.5518 40.3047 11.5282 41.3867 12.1785 42.0688C12.8406 42.7627 13.9284 42.7863 14.6141 42.1276L17.1799 39.6343C17.8656 38.9875 17.8893 37.9055 17.239 37.2234L17.2271 37.2116C16.5768 36.5295 15.4891 36.5059 14.8033 37.1528'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M15.8318 40.9516C15.1578 41.6102 15.146 42.6922 15.8081 43.3626C16.4703 44.0447 17.5698 44.0565 18.2438 43.3861L18.8941 42.7628C19.568 42.1042 19.5798 41.0222 18.9177 40.3518L18.9059 40.3401C18.2438 39.6697 17.156 39.6579 16.4821 40.3165'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M32.148 29.5906C29.9016 30.0493 29.3222 29.4848 28.1162 28.7321'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M7.11793 35.0829C6.52675 34.5184 5.3444 33.9774 3.78369 33.9774'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M34.0518 17.9356V1H9.45883L5.67529 4.76347V18.8765'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M11.3508 3.82263V6.64524H8.51318'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M14.188 11.3496H27.4304'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M14.188 15.113H21.7551'
                                                                stroke='#1A224E'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                            <path
                                                                d='M12.2964 19.8174H22.7011'
                                                                stroke='#F15F34'
                                                                strokeWidth='2'
                                                                strokeMiterlimit='10'
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className='content-wrapper'>
                                                        <h5>
                                                            Work Better Together
                                                        </h5>
                                                        <p className='small'>
                                                            Share and
                                                            collaborate in
                                                            Adaje's multi-user
                                                            environment. Changes
                                                            made by any user are
                                                            automatically
                                                            viewable by other
                                                            permitted users.
                                                            Share custom limited
                                                            view-only access to
                                                            clients via a client
                                                            portal.
                                                        </p>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className='screen-wrapper'>
                                    <img
                                        className='screen'
                                        src={AdvisorsScreen}
                                    />
                                </div>
                            </div>
                        </section>
                    </main>
                    {this.props.footer}
                </div>
            </body>
        );
    }
}
