import React from 'react';
import { Link } from 'react-router-dom';

class MobileNavigation extends React.Component {
    render() {
        return (
            <aside
                id='mobile-navigation-wrapper'
                className='mobile-navigation-wrapper'>
                <ul className='menu'>
                    <li className='menu-item'>
                        <Link to='/borrower'>For Borrowers</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to='/advisorbanker'>For Advisors & Bankers</Link>
                    </li>
                    <li className='menu-item'>
                        <Link to='/company'>Company</Link>
                    </li>
                    <li className='menu-item'>{this.props.signInUrl()}</li>
                    <li className='menu-item'>
                        <a
                            className='landing-btn'
                            href='https://help.adajeinc.com/meetings/dan-silva/personalized-adaje-demo'>
                            Schedule Demo
                        </a>
                    </li>
                </ul>
            </aside>
        );
    }
}

export default MobileNavigation;
