import React from 'react';
import { Link } from 'react-router-dom';

class MainNavigation extends React.Component {
    render() {
        return (
            <header className='global-header'>
                <div className='main-navigation container-fluid'>
                    <Link to='/' className='logo-link'>
                        <svg
                            width='179'
                            height='45'
                            viewBox='0 0 179 45'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <g clipPath='url(#clip0)'>
                                <path
                                    className='st1'
                                    d='M78.192 28.728H64.944L62.28 36.648H56.16L67.68 5.544H75.672L87.264 36.648H80.928L78.192 28.728ZM66.456 24.264H76.608L75.312 20.448C74.232 17.28 73.152 13.752 72.216 10.656H70.92C69.984 13.752 68.832 17.28 67.752 20.448L66.456 24.264Z'
                                    fill='#1A224E'
                                />
                                <path
                                    className='st1'
                                    d='M106.704 30.24H106.344C105.12 34.416 102.168 37.368 97.848 37.368C92.232 37.368 88.992 32.832 88.992 24.624C88.992 16.272 92.304 11.664 97.992 11.736C102.384 11.664 105.12 14.472 106.344 18.72H106.704V3.384H112.248V36.648H106.704V30.24ZM106.704 25.56V23.688C106.704 19.512 104.256 16.704 100.512 16.704C96.48 16.704 94.464 19.224 94.464 24.552C94.464 29.808 96.336 32.328 100.512 32.328C104.256 32.328 106.704 29.664 106.704 25.56Z'
                                    fill='#1A224E'
                                />
                                <path
                                    className='st1'
                                    d='M133.56 30.816H132.984C131.76 34.776 128.952 37.152 124.632 37.152C120.312 37.152 117.216 35.064 117.216 30.312C117.216 25.992 119.88 22.824 126 22.824H133.272V21.168C133.272 18 131.976 16.2 128.52 16.2C125.64 16.2 123.984 17.64 123.408 20.52L118.152 19.296C119.088 14.688 122.544 11.736 128.808 11.736C135.864 11.736 138.888 15.048 138.888 21.096V36.648H133.632V30.816H133.56ZM133.344 27.432V26.424H127.008C124.2 26.424 122.616 27.432 122.616 29.448C122.616 31.176 123.696 32.688 126.864 32.688C130.752 32.832 133.344 30.528 133.344 27.432Z'
                                    fill='#1A224E'
                                />
                                <path
                                    className='st1'
                                    d='M142.344 45C141.48 45 140.688 44.928 140.04 44.712L140.328 39.888C140.976 39.96 141.552 40.104 142.2 40.104C144.144 40.104 144.792 38.736 144.792 36.648V12.312H150.336V36.072C150.336 41.544 148.032 45 142.344 45Z'
                                    fill='#1A224E'
                                />
                                <path
                                    className='st1'
                                    d='M155.232 24.768C155.232 16.56 159.912 11.664 167.544 11.664C174.888 11.664 178.488 16.128 178.488 22.824C178.488 23.76 178.488 24.84 178.416 26.064H160.848C161.28 30.456 163.512 32.688 167.616 32.688C171.504 32.688 172.872 30.816 173.52 28.512L178.272 29.88C177.12 34.272 174.096 37.296 167.544 37.296C160.056 37.224 155.232 32.904 155.232 24.768ZM160.848 22.32H173.376C173.16 18.144 171.288 15.912 167.4 15.912C163.584 15.984 161.352 18 160.848 22.32Z'
                                    fill='#1A224E'
                                />
                                <path
                                    className='st0'
                                    d='M150.336 3.384H144.792V8.928H150.336V3.384Z'
                                    fill='#F15F34'
                                />
                                <path
                                    className='st0'
                                    d='M36.576 23.616H23.616V36.576H36.576V23.616Z'
                                    fill='#F15F34'
                                />
                                <path
                                    className='st1'
                                    d='M25.992 0L0 25.992V36.648H10.656L36.648 10.656V0H25.992ZM31.968 8.712C31.968 8.712 30.528 12.168 21.384 21.384C12.168 30.528 8.712 31.968 8.712 31.968H4.68V27.936C4.68 27.936 6.12 24.48 15.264 15.264C24.408 6.12 27.936 4.68 27.936 4.68H31.968V8.712Z'
                                    fill='#1A224E'
                                />
                            </g>
                            <defs>
                                <clipPath id='clip0'>
                                    <rect
                                        className='st1'
                                        width='178.488'
                                        height='45'
                                        fill='white'
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>
                    <div className='menu-grid'>
                        <div id='mobile-nav-toggle' className='nav-toggle'>
                            <span className='text'>Menu</span>
                            <span className='hamburger'>&nbsp;</span>
                        </div>
                        <nav className='primary'>
                            <ul className='menu'>
                                <li className='menu-item'>
                                    <Link to='/borrower'>For Borrowers</Link>
                                </li>
                                <li className='menu-item'>
                                    <Link to='/advisorbanker'>
                                        For Advisors & Bankers
                                    </Link>
                                </li>
                                <li className='menu-item'>
                                    <Link to='/company'>Company</Link>
                                </li>
                                <li className='menu-item'>
                                    {this.props.signInUrl()}
                                </li>
                                <li className='menu-item'>
                                    <a
                                        className='landing-btn'
                                        href='https://help.adajeinc.com/meetings/dan-silva/personalized-adaje-demo'>
                                        Schedule Demo
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}

export default MainNavigation;
