import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './Home';
import MainNavigation from './MainNavigation';
import MobileNavigation from './MobileNavigation';
import Footer from './Footer';
import AdvisorBankerPage from './AdvisorBankerPage';
import Company from './Company';
import BorrowersPage from './BorrowersPage';
import { Helmet } from 'react-helmet';
import ShareFacebook from './assets/images/share_facebook.png';
import ShareTwitter from './assets/images/share_twitter.png';
import AppleIcon57 from './assets/favicon/apple-icon-57x57.png';
import AppleIcon60 from './assets/favicon/apple-icon-60x60.png';
import AppleIcon72 from './assets/favicon/apple-icon-72x72.png';
import AppleIcon76 from './assets/favicon/apple-icon-76x76.png';
import AppleIcon114 from './assets/favicon/apple-icon-114x114.png';
import AppleIcon120 from './assets/favicon/apple-icon-120x120.png';
import AppleIcon144 from './assets/favicon/apple-icon-144x144.png';
import AppleIcon152 from './assets/favicon/apple-icon-152x152.png';
import AppleIcon180 from './assets/favicon/apple-icon-180x180.png';
import AndroidIcon192 from './assets/favicon/android-icon-192x192.png';
import Favicon32 from './assets/favicon/favicon-32x32.png';
import Favicon96 from './assets/favicon/favicon-96x96.png';
import Favicon16 from './assets/favicon/favicon-16x16.png';
import MsIcon144 from './assets/favicon/ms-icon-144x144.png';
import { constants } from './constants';
class LandingPage extends React.Component {
    render() {
        const loginUrl = () => <a href={constants.loginRedirectUrl}>Sign in</a>;

        const mainNav = <MainNavigation signInUrl={loginUrl} />;

        const mobileNav = <MobileNavigation signInUrl={loginUrl} />;

        const footer = <Footer signInUrl={loginUrl} />;

        return (
            <div className='landing'>
                <Helmet>
                    <link rel='canonical' href='' />
                    <meta property='og:locale' content='en_US' />
                    <meta property='og:type' content='website' />
                    <meta
                        property='og:url'
                        content='https://www.adajeinc.com/'
                    />
                    <meta property='og:site_name' content='Adaje' />
                    <meta property='og:image' content={ShareFacebook} />
                    <meta property='og:image:width' content='1400' />
                    <meta property='og:image:height' content='800' />
                    <meta property='og:image' content={ShareTwitter} />
                    <link
                        rel='apple-touch-icon'
                        sizes='57x57'
                        href={AppleIcon57}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='60x60'
                        href={AppleIcon60}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='72x72'
                        href={AppleIcon72}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='76x76'
                        href={AppleIcon76}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='114x114'
                        href={AppleIcon114}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='120x120'
                        href={AppleIcon120}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='144x144'
                        href={AppleIcon144}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='152x152'
                        href={AppleIcon152}
                    />
                    <link
                        rel='apple-touch-icon'
                        sizes='180x180'
                        href={AppleIcon180}
                    />
                    <link
                        rel='icon'
                        type='image/png'
                        sizes='192x192'
                        href={AndroidIcon192}
                    />
                    <link
                        rel='icon'
                        type='image/png'
                        sizes='32x32'
                        href={Favicon32}
                    />
                    <link
                        rel='icon'
                        type='image/png'
                        sizes='96x96'
                        href={Favicon96}
                    />
                    <link
                        rel='icon'
                        type='image/png'
                        sizes='16x16'
                        href={Favicon16}
                    />
                    {/*<link rel="manifest" href={Manifest}/>*/}
                    <meta name='msapplication-TileColor' content='#ffffff' />
                    <meta name='msapplication-TileImage' content={MsIcon144} />
                    <meta name='theme-color' content='#ffffff' />
                    <link
                        rel='stylesheet'
                        href='https://use.typekit.net/hua5dfv.css'
                    />
                </Helmet>
                <BrowserRouter>
                    <Switch>
                        <Route
                            exact
                            path='/'
                            render={routeProps => (
                                <Home
                                    {...routeProps}
                                    {...{
                                        mainNav: mainNav,
                                        mobileNav: mobileNav,
                                        footer: footer
                                    }}
                                />
                            )}
                        />
                        <Route
                            path='/borrower'
                            render={routeProps => (
                                <BorrowersPage
                                    {...routeProps}
                                    {...{
                                        mainNav: mainNav,
                                        mobileNav: mobileNav,
                                        footer: footer
                                    }}
                                />
                            )}
                        />
                        <Route
                            path='/advisorbanker'
                            render={routeProps => (
                                <AdvisorBankerPage
                                    {...routeProps}
                                    {...{
                                        mainNav: mainNav,
                                        mobileNav: mobileNav,
                                        footer: footer
                                    }}
                                />
                            )}
                        />
                        <Route
                            path='/company'
                            render={routeProps => (
                                <Company
                                    {...routeProps}
                                    {...{
                                        mainNav: mainNav,
                                        mobileNav: mobileNav,
                                        footer: footer
                                    }}
                                />
                            )}
                        />
                        <Route component={Home} />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default LandingPage;
